<template>
  <div>
    <el-card v-loading="isLoading" shadow="always">
      <template #header>
        <el-row type="flex" justify="space-between" align="center">
          <el-col :md="8" :sm="8" :xs="10"
            ><el-row type="flex" justify="start"
              ><h4>{{ $route.name }}</h4></el-row
            ></el-col
          >
          <el-col :md="16" :sm="16" :xs="14">
            <el-row type="flex" justify="end">
              <el-col :md="24">
                <el-row :gutter="4" type="flex" justify="end" align="center">
                  <el-button
                    type="primary"
                    icon="el-icon-s-operation"
                    class="mb-0"
                    size="medium"
                    @click="openFilterCashierModal()"
                  ></el-button>
                  <!--<el-button
                  type="primary"
                  class="mb-0"
                  icon="el-icon-download"
                  :loading="isLoadingDownloadButton"
                  size="medium"
                  @click="downloadReport()"
                ></el-button>-->
                </el-row>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </template>
      <vue-apex-charts
        type="area"
        v-if="CashiersSeries?.length"
        :options="getAreaOptions()"
        :series="CashiersSeries"
      ></vue-apex-charts>
      <el-empty v-else></el-empty>
    </el-card>
    <el-card v-loading="isLoading" shadow="always" class="mt-1">
      <vue-apex-charts
        type="donut"
        v-if="CashierDonuts?.length"
        :options="getDonutOptions()"
        :series="CashierDonuts"
      ></vue-apex-charts>
      <el-empty v-else></el-empty>
    </el-card>

    <el-card v-loading="isLoading" class="mt-1">
      <el-table :data="CashiersData">
        <el-table-column
          v-for="(a, id) in CashiersColumns"
          :key="a"
          :label="
            hasValidNumber(a[0]) ? dateFormatter.format(new Date(a[0])) : a[0]
          "
          :prop="`${id}`"
          :formatter="
            (r) =>
              hasValidNumber(r[id])
                ? currencyFormatter.format(Number(r[id]))
                : r[id]
          "
        ></el-table-column>
      </el-table>
    </el-card>

    <filter-cashier-modal
      :showModal="showFilterCashierModal"
      :filterOptions="filterOptions"
      @close-modal="showFilterCashierModal = false"
      @update-filters="updateFilters"
    ></filter-cashier-modal>
  </div>
</template>

<script>
import { ElNotification /*ElMessageBox*/ } from "element-plus";
import FilterCashierModal from "./modals/FilterCashierModal.vue";
import VueApexCharts from "vue3-apexcharts";

export default {
  name: "CashierCloseResults",
  components: {
    FilterCashierModal,
    VueApexCharts,
  },
  data: () => ({
    showFilterCashierModal: false,
    cashiers: null,
    filterOptions: { closed_at_start: getFirstDayInMonth()?.toISOString() },
    isLoadingDownloadButton: false,
    isLoading: false,
    dateFormatter: new Intl.DateTimeFormat("pt-BR", {
      day: "numeric",
      month: "short",
    }),
    currencyFormatter: new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }),
    numberFormatter: new Intl.NumberFormat("pt-BR", {
      style: "decimal",
    }),
  }),
  mounted() {
    this.fetchCashiers();
  },
  computed: {
    Cashiers() {
      return this.cashiers || [];
    },
    CashiersColumns() {
      return [["empresa"], ...(this.CashiersSeries?.[0]?.data || [])];
    },
    CashiersData() {
      return this.CashiersSeries.map((c, i) => [
        c.name,
        ...(this.CashiersSeries?.[i]?.data?.map((_) => _[1]) || []),
      ]);
    },
    CashierDonuts() {
      const sum = [];
      for (let c = 0; c < this.CashiersSeries.length; c++)
        sum.push(this.sumTotalSold(c));

      return sum;
    },
    CashiersSeries() {
      const cashiers = this.orderByFirms();
      for (let c in cashiers) {
        const _ = cashiers[c].reduce((t, e) => {
          if (t[ensureItsZeroHour(e.closed_at)])
            t[ensureItsZeroHour(e.closed_at)].push(e);
          else t[ensureItsZeroHour(e.closed_at)] = [e];
          return t;
        }, {});

        cashiers[c] = Object.entries(_)?.map((e) => ({
          total_sells: e[1].reduce((t, e) => (t += Number(e.total_sells)), 0),
          closed_at: new Date(e[0].slice(0, 11)?.replace("T", " ")),
        }));
      }

      return Object.entries(cashiers)?.map((e) => ({
        name: e[0],
        data: e[1]?.map((e) => [
          new Date(e.closed_at).valueOf(),
          Number(e.total_sells),
        ]),
      }));
    },
  },
  methods: {
    getDonutOptions() {
      return {
        chart: {
          height: 350,
          type: "donut",
          zoom: {
            enabled: false,
          },
          locales: [
            {
              name: "pt-br",
              options: {
                months: [
                  "Janeiro",
                  "Fevereiro",
                  "Março",
                  "Abril",
                  "Maio",
                  "Junho",
                  "Julho",
                  "Agosto",
                  "Setembro",
                  "Outubro",
                  "Novembro",
                  "Dezembro",
                ],
                shortMonths: [
                  "Jan",
                  "Fev",
                  "Mar",
                  "Abr",
                  "Mai",
                  "Jun",
                  "Jul",
                  "Ago",
                  "Set",
                  "Out",
                  "Nov",
                  "Dez",
                ],
                days: [
                  "Domingo",
                  "Segunda",
                  "Terça",
                  "Quarta",
                  "Quinta",
                  "Sexta",
                  "Sábado",
                ],
                shortDays: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
                toolbar: {
                  exportToSVG: "Baixar SVG",
                  exportToPNG: "Baixar PNG",
                  exportToCSV: "Baixar CSV",
                  menu: "Menu",
                  selection: "Selecionar",
                  selectionZoom: "Selecionar Zoom",
                  zoomIn: "Aumentar",
                  zoomOut: "Diminuir",
                  pan: "Navegação",
                  reset: "Reiniciar Zoom",
                },
              },
            },
          ],
          defaultLocale: "pt-br",
        },
        //colors: ["#0d84ff"],
        dataLabels: {
          enabled: false,
        },
        labels: Object.keys(this.orderByFirms()),
        series: this.CashierDonuts,
        legend: {
          formatter: (label, opts) =>
            `${label} ( ${this.currencyFormatter.format(
              this.sumTotalSold(opts.seriesIndex)
            )} )`,
        },
        theme: {
          mode: "light",
          palette: "palette0",
        },
        stroke: {
          curve: "smooth",
        },
        title: {
          text: "Participação no Resultado",
          align: "left",
        },
        /*grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 1,
          },
        },*/
        xaxis: {
          type: "datetime",
        },
        yaxis: {
          labels: {
            formatter: (v) => this.currencyFormatter.format(Number(v) || 0),
          },
        },
      };
    },
    getAreaOptions() {
      return {
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
          locales: [
            {
              name: "pt-br",
              options: {
                months: [
                  "Janeiro",
                  "Fevereiro",
                  "Março",
                  "Abril",
                  "Maio",
                  "Junho",
                  "Julho",
                  "Agosto",
                  "Setembro",
                  "Outubro",
                  "Novembro",
                  "Dezembro",
                ],
                shortMonths: [
                  "Jan",
                  "Fev",
                  "Mar",
                  "Abr",
                  "Mai",
                  "Jun",
                  "Jul",
                  "Ago",
                  "Set",
                  "Out",
                  "Nov",
                  "Dez",
                ],
                days: [
                  "Domingo",
                  "Segunda",
                  "Terça",
                  "Quarta",
                  "Quinta",
                  "Sexta",
                  "Sábado",
                ],
                shortDays: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
                toolbar: {
                  exportToSVG: "Baixar SVG",
                  exportToPNG: "Baixar PNG",
                  exportToCSV: "Baixar CSV",
                  menu: "Menu",
                  selection: "Selecionar",
                  selectionZoom: "Selecionar Zoom",
                  zoomIn: "Aumentar",
                  zoomOut: "Diminuir",
                  pan: "Navegação",
                  reset: "Reiniciar Zoom",
                },
              },
            },
          ],
          defaultLocale: "pt-br",
        },
        //colors: ["#0d84ff"],
        dataLabels: {
          enabled: false,
        },
        legend: {
          formatter: (label, opts) =>
            `${label} ( ${this.currencyFormatter.format(
              this.sumTotalSold(opts.seriesIndex)
            )} )`,
        },
        theme: {
          mode: "light",
          palette: "palette0",
        },
        stroke: {
          curve: "smooth",
        },
        title: {
          text: "Resultado de Vendas por Empresa",
          align: "left",
        },
        /*grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 1,
          },
        },*/
        xaxis: {
          type: "datetime",
        },
        yaxis: {
          labels: {
            formatter: (v) => this.currencyFormatter.format(Number(v) || 0),
          },
        },
      };
    },
    sumTotalSold(index) {
      const _ = this.CashiersSeries[index];

      if (_) return _?.data?.reduce((t, e) => (t += e[1]), 0);
      else return 0;
    },
    downloadReport() {
      this.changeDownloadButtonStatus();

      ElNotification.info({
        title: "Gerando o relatório",
        message: "Isso pode levar alguns segundos",
      });

      const url = new URL(`${this.$store.state.apiUrl}cashiers/report`);
      url.search = new URLSearchParams({
        ...this.filterOptions,
        status: this.cashierStatus,
      });
      fetch(url, {
        credentials: "include",
        headers: {
          Accept: "blob",
        },
      })
        .then((response) => {
          if (response.ok) return response.blob();
          else throw response.json();
        })
        .then((blob) => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = `Relatório de Fechamento de Caixa - ${
            this.filterOptions.firm_id || ""
          }.pdf`;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(() => {})
        .finally(() => this.changeDownloadButtonStatus());
    },
    changeDownloadButtonStatus() {
      this.isLoadingDownloadButton = !this.isLoadingDownloadButton;
    },
    openFilterCashierModal() {
      this.showFilterCashierModal = true;
    },
    updateFilters(filters) {
      this.filterOptions = filters || {};
      this.fetchCashiers();
    },
    calculateResult(r) {
      return Number(Number(r.total_cashier) - Number(r.check_total)) || 0;
    },
    orderByFirms() {
      return this.Cashiers?.reduce((t, c) => {
        if (t[c?.firm?.name]) t[c?.firm?.name].push(c);
        else t[c?.firm?.name] = [c];
        return t;
      }, {});
    },
    hasValidNumber(e) {
      return !isNaN(Number(e));
    },
    fetchCashiers() {
      this.isLoading = true;
      const url = new URL(`${this.$store.state.apiUrl}cashiers`);

      if (this.filterOptions?.closed_at_end) {
        const _ = new Date(this.filterOptions?.closed_at_end);
        _.setDate(_.getDate() + 1);

        this.filterOptions.closed_at_end = _;
      }

      if (this.filterOptions?.closed_at_start) {
        const _ = new Date(this.filterOptions.closed_at_start);
        _.setDate(_.getDate() - 1);
        this.filterOptions.closed_at_start = _;
      }

      url.search = new URLSearchParams({
        status: "completed",
        ...(this.filterOptions || {}),
      });
      fetch(url, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else return response.text();
        })
        .then((json) => {
          this.cashiers = json;
        })
        .catch(() => (this.hasError = true))
        .finally(() => (this.isLoading = false));
    },
  },
};
const ensureItsZeroHour = (e) => e?.slice(0, 11);
const getFirstDayInMonth = () =>
  new Date(new Date().getFullYear(), new Date().getMonth(), 1);
</script>
<style scoped>
.el-card {
  width: 100%;
}
tr.unavailable {
  background-color: #fffde7;
}
.is-negative {
  color: red !important;
}
.is-positive {
  color: blue !important;
}
tr.problem {
  background-color: #fbe9e7;
}
.mb-0 {
  margin-bottom: 0px !important;
}
.summary {
  color: #909399;
  font-weight: 700 !important;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", SimSun, sans-serif;
  font-size: 12px;
}
.mt-1 {
  margin-top: 8px;
}
.summary-black {
  color: #333 !important;
}
</style>